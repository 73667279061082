import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';

import Header from '../components/Header';
import TopMenu from '../components/TopMenu';
import Footer from '../components/Footer';
import Publication from '../components/Publication';
import HighlightBox from '../components/HighlightBox';
import ExternalLink from '../components/ExternalLink';
import styles from './index.module.scss';

import { menuNodesSorter, listOrderSorter } from '../utils';

export default class HomePage extends PureComponent {
  constructor() {
    super();

    this.state = {
      indexEdges: [],
    };
  }

  componentDidMount() {
    const { data: { allMarkdownRemark: { edges } } } = this.props;

    this.setState({
      indexEdges: edges.filter(e => e.node.frontmatter.path.startsWith('/index')),
    });
  }

  renderContent = () => {
    const { indexEdges } = this.state;
    const introTextNode = indexEdges.find(({ node: { frontmatter: { section } } }) => section === 'introText');
    const getToKnowNode = indexEdges.find(({ node: { frontmatter: { section } } }) => section === 'getToKnow');
    const publications = indexEdges
      .filter(({ node: { frontmatter: { type } } }) => type === 'publications')
      .sort(listOrderSorter)
      .map(({ node: { frontmatter: { imageUrl: imgSrc, description: text, link, title } } }) => ({
        imgSrc,
        text,
        link,
        title,
      }));
    const highlights = [
      {
        imgSrc: 'https://firebasestorage.googleapis.com/v0/b/fao-aquastat.appspot.com/o/Images%2Fhomepage%2Faquastat-database.jpg?alt=media&token=e3200975-3291-43a6-92ad-eebfa4075296',
        link: 'http://www.fao.org/nr/water/aquastat/data/query/index.html?lang=en',
        label: 'Country Statistics',
      },
      {
        imgSrc: 'https://firebasestorage.googleapis.com/v0/b/fao-aquastat.appspot.com/o/Images%2Fhomepage%2Fmaps.jpg?alt=media&token=38e27523-ece0-4ab5-a5b0-a438b258a2a5',
        link: 'http://www.fao.org/nr/water/aquamaps/',
        label: 'Maps',
      },
      {
        imgSrc: 'https://firebasestorage.googleapis.com/v0/b/fao-aquastat.appspot.com/o/Images%2Fhomepage%2Fcountry-profile.jpg?alt=media&token=dfcaf504-4d77-4a60-ba47-05a37be384ed',
        link: '/countries-and-basins/country-profiles/',
        label: 'Country Profiles',
      },
    ];

    return (
      <div className={styles.content}>
        <div className={styles.introText} dangerouslySetInnerHTML={{ __html: introTextNode && introTextNode.node.html }} />

        <div className={styles.contentSection}>
          <h3>Highlights</h3>

          <div className={styles.highlights}>
            {highlights.map(props => <HighlightBox key={props.label} {...props} />)}
          </div>
        </div>

        <div className={styles.contentSection}>
          <h3>Get to know</h3>

          <div className={styles.getToKnowText}>
            <img src="https://firebasestorage.googleapis.com/v0/b/fao-aquastat.appspot.com/o/Images%2Fhomepage%2Fgettoknow.jpg?alt=media&token=9f7e63d2-86dd-4936-a9e1-a19fb5baccfe" alt="Get to know" />

            <span dangerouslySetInnerHTML={{ __html: getToKnowNode && getToKnowNode.node.html }} />
          </div>
        </div>

        <div className={styles.contentSection}>
          <h3>Key publications</h3>
          {publications.map(props => <Publication key={props.title} {...props} />)}
        </div>
      </div>
    );
  };

  renderSidebarLinks = () => {
    const { indexEdges } = this.state;
    const links = indexEdges
      .filter(({ node: { frontmatter: { type } } }) => type === 'links')
      .sort(menuNodesSorter);
    const projectsLinks = links
      .filter(({ node: { frontmatter: { section } } }) => section === 'projects')
      .sort(menuNodesSorter);
    const seeAlsoLinks = links
      .filter(({ node: { frontmatter: { section } } }) => section === 'seeAlso')
      .sort(menuNodesSorter);
    const externalLinks = links
      .filter(({ node: { frontmatter: { section } } }) => section === 'externalLinks')
      .sort(menuNodesSorter);

    return [
      <div key="Projects" className={styles.sidebarBox}>
        <h3>Projects</h3>
        {projectsLinks.map(({ node: { frontmatter: { link, title } } }) => (
          <ExternalLink key={link} link={link} label={title} icon="world" />
        ))}
      </div>,
      <div key="See also" className={styles.sidebarBox}>
        <h3>See also</h3>
        {seeAlsoLinks.map(({ node: { frontmatter: { link, title } } }) => (
          <ExternalLink key={link} link={link} label={title} icon="world" />
        ))}
      </div>,
      <div key="External links" className={styles.sidebarBox}>
        <h3>External links</h3>
        {externalLinks.map(({ node: { frontmatter: { link, title } } }) => (
          <ExternalLink key={link} link={link} label={title} icon="world" />
        ))}
      </div>,
    ];
  }

  renderSidebar = () => {
    const { indexEdges } = this.state;
    const didYouKnowNode = indexEdges.find(({ node: { frontmatter: { section } } }) => section === 'didYouKnow');
    const recentlyUpdatedNode = indexEdges.find(({ node: { frontmatter: { section } } }) => section === 'recentlyUpdated');

    return (
      <div className={styles.sidebar}>
        <div className={styles.didYouKnowBox}>
          <h3>Did you know?</h3>
          <div dangerouslySetInnerHTML={{ __html: didYouKnowNode && didYouKnowNode.node.html }} />
        </div>

        {(recentlyUpdatedNode && recentlyUpdatedNode.node.html !== '') && (
          <div className={styles.sidebarBox}>
            <h3>Recently updated</h3>
            <div dangerouslySetInnerHTML={{ __html: recentlyUpdatedNode.node.html }} />
          </div>
        )}

        {this.renderSidebarLinks()}

        <a className="twitter-timeline" data-height="400" href="https://twitter.com/FAOAQUASTAT?ref_src=twsrc%5Etfw">
          Tweets by FAOAQUASTAT
        </a>

        <div className={styles.contactBox}>
          <h3>Contact</h3>
          <a href="mailto:aquastat@fao.org">aquastat@fao.org</a>
        </div>
      </div>
    );
  };

  render() {
    const { data: { site }, location } = this.props;

    return (
      <div>
        <Helmet
          title={site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'FAO AQUASTAT' },
            { name: 'keywords', content: 'fao, water, aquastat, water production' },
          ]}
        />
        <Header siteTitle={site.siteMetadata.title} location={{}} />

        <div className="app-wrapper">
          <h2 className="site-title">AQUASTAT - FAO&apos;s Information System on Water and Agriculture</h2>
          <TopMenu location={location.pathname} />

          <div className={styles.wrapper}>
            <div className={styles.headerImage}>
              <img src="https://firebasestorage.googleapis.com/v0/b/fao-aquastat.appspot.com/o/Images%2Fbanners%2Fhome1.jpg?alt=media&token=4daae394-9fb6-416f-8ab3-7e4e029ed9a8" alt="FAO AQUASTAT" />
            </div>
            {this.renderContent()}
            {this.renderSidebar()}
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

HomePage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

/* eslint-disable no-undef */
export const pageQuery = graphql`
  query HomePageQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark {
      edges {
        node {
          id
          html
          frontmatter {
            path
            title
            section
            type
            link
            imageUrl
            menuOrder
            listOrder
            description
          }
        }
      }
    }
  }
`;
