import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

class Publication extends PureComponent {
  render() {
    const { imgSrc, link, title, text } = this.props;

    return (
      <div className={styles.wrapper}>
        <div className={styles.image}>
          <a className={styles.link} href={link} alt={title} target="_blank" rel="noopener noreferrer">
            <img src={imgSrc} alt={title} />
          </a>
        </div>
        <div className={styles.text}>
          <a className={styles.link} href={link} alt={title} target="_blank" rel="noopener noreferrer">
            <h3 className={styles.title}>{title}</h3>
          </a>
          <p className={styles.excerpt}>{text}</p>
        </div>
      </div>
    );
  }
}

Publication.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default Publication;
