import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';

import styles from './styles.module.scss';

class HighlightBox extends PureComponent {
  render() {
    const { imgSrc, link, label } = this.props;

    return (
      <div className={styles.wrapper}>
        {link.startsWith('http') ? (
          <a href={link} className={styles.link} alt={label} target="_blank" rel="noopener noreferrer">
            <img src={imgSrc} className={styles.image} alt={label} />
            <div className={styles.textContainer}>
              <h3>{label}</h3>
            </div>
          </a>
        ) : (
          <Link to={link} className={styles.link} alt={label}>
            <img src={imgSrc} className={styles.image} alt={label} />
            <div className={styles.textContainer}>
              <h3>{label}</h3>
            </div>
          </Link>
        )}
      </div>
    );
  }
}

HighlightBox.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default HighlightBox;
