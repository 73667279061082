import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon';

import styles from './styles.module.scss';

class ExternalLink extends PureComponent {
  render() {
    const { link, label, icon } = this.props;

    return (
      <p className={styles.externalLink}>
        <i className={styles.icon}><Icon glyph={icon} width="14" height="14" /></i>
        <a href={link} alt={label} target="_blank" rel="nofollow noopener noreferrer">{label}</a>
      </p>
    );
  }
}

ExternalLink.propTypes = {
  link: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

export default ExternalLink;
